import {Box, Grid, Typography} from "@mui/material";
import {Discount} from "../../../../../../model/Discount";
import {fonts, semiBoldText} from "../../../../../../../app.style";
import {useContext} from "react";
import {AppContext, AppModalContext} from "../../../../../common/appWrapper/AppWrapper";
import DeleteEditBtns from "../../../../../common/appButton/deleteEditBtns/DeleteEditBtns";
import {AuthContext} from "../../../../../../../App";
import DiscountForm from "../../../../../common/appForm/discountForm/DiscountForm";

const CruiseDiscount = ({discount} : {discount: Discount}) => {
  const {jwt} = useContext(AuthContext);
  const {translation, appCacheApi} = useContext(AppContext);
  const {setConfirmModalHeaderText, setConfirmModalHandler, setModalHeaderText, setModalChildren} = useContext(AppModalContext);

  const editApiCallback = () => {
    setModalHeaderText(translation.modalHeader.editDiscount);
    setModalChildren(<DiscountForm discount={discount} cruiseId={discount.cruiseId}/>);
  }

  const deleteApiCallback = () => {
    setConfirmModalHeaderText(translation.modalHeader.deleteDiscount);
    setConfirmModalHandler(() => () => {
      appCacheApi.deleteDiscounts([discount.id], jwt)
    })
  }

  return (
    <Box sx={{display: "flex", justifyContent: "center", mb: 2}}>
      <Grid container spacing={1} sx={{maxWidth: "800px"}}>
        <Grid item xs={12} sm={7} sx={{display: "flex", justifyContent: "center"}}>
          <Typography sx={[fonts.spartan16, semiBoldText, {display: "flex", alignItems: "center"}]}>
            {translation.page.admin.cruiseDetails.discount.code} {discount.code}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} sx={{display: "flex", justifyContent: "center"}}>
          <Typography sx={[fonts.spartan16, semiBoldText, {display: "flex", alignItems: "center"}]}>
            {translation.page.admin.cruiseDetails.discount.amount} {Math.round(discount.discountAmt * 100)}%
          </Typography>
        </Grid>
        <Grid item xs={12} sm={2} sx={{display: "flex", justifyContent: "center"}}>
          <DeleteEditBtns editApiCallback={editApiCallback} deleteApiCallback={deleteApiCallback}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CruiseDiscount;